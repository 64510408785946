// ------------------------------------------------Plan
.plan-container {
  position: relative;
  text-align: center;
  font-family: "Inter";
  font-style: normal;
  overflow: hidden;
  h1 {
    margin-top: 50px;
    font-weight: 800;
    font-size: 35px;
    line-height: 33px;
  }
  p {
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 14px;
    line-height: 40px;
  }
  .plan-toggle {
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .plan-btn {
      display: flex;
      justify-content: center;
      font-weight: 600;
      font-size: 12px;
      align-items: center;
      border-radius: 20px;
      color: #090a0a;
      border: none;
      .plan-btn-w {
        margin: 0px 10px;
        padding: 18px 7px 18px 10px;
      }
      .plan-btn-y {
        margin: 0px 10px;
        padding: 18px 7px 18px 10px;
      }
      .plan-btn-m {
        background-color: #f95c04;
        color: #fff;
        padding: 18px 7px;
      }
    }
  }
  .plan-price {
    font-weight: 800;
    font-size: 14px;
    line-height: 22px;
    color: #1083a9;
  }
  .plan-duration {
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    color: rgba(72, 72, 72, 0.6);
  }
  .plan-selection {
    margin: 20px auto 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: white;
    background: #f95c04;
    width: 80px;
    padding: 3% 5%;
    border-radius: 30px;
    cursor: pointer;
  }
  .table-divider {
    background: #e8f9ff;
  }
  .table-d {
    background-color: #fee7d9;
    padding-bottom: 30px;
  }
  .skip-payment {
    display: flex;
    justify-content: flex-end;
    margin-right: 80px;
    align-items: center;
    font-size: 14px;
    a {
      margin-right: 5px;
      color: #484848 !important;
      font-weight: 700;
    }
  }
  .plan-table-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    .header-start {
      padding: 0px 20px 0px 25px;
    }
    .table-left-header {
      display: flex;
      justify-content: left;
      align-content: flex-end;
      padding: 130px 0px 10px 30px;
      width: 100%;
      background: #1083a9;
      color: #fff;
      font-weight: 600;
      font-size: 20px;
      line-height: 22px;
      border-top-left-radius: 20px;
    }
    .table-divider {
      background: #e8f9ff;
    }
    .table-left {
      padding: 10px 30px;
      font-weight: 600;
      font-size: 14px;
      width: 18%;
      background: #1083a9;
      border-collapse: collapse;
      color: #fff;
      text-align: left;
      span {
        padding: 10px 0px;
        font-size: 14px;
        font-weight: 300;
      }
    }
    .table-left-end {
      width: 250px;
      background: #1083a9;
      border-bottom-left-radius: 20px;
      height: 20px;
    }

    .table-top {
      background: #1083a9;
    }
    .table {
      width: 90%;
      border-radius: 20px;
      justify-content: center;
      border-spacing: 50px 0px;
      -webkit-box-shadow: 0px 0px 6px 5px rgba(0, 0, 0, 0.25);
      box-shadow: 0px 0px 6px 5px rgba(0, 0, 0, 0.25);
      margin-bottom: 50px;
    }
    tr {
      border: none;
      td {
        font-size: 14px;
        width: 12%;
        padding: 10px 5px;
      }
    }
    tr:last-child {
      td {
        padding-bottom: 50px;
      }
    }
    th {
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;

      span {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
    p {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 30px 0px 50px 0px;
      font-weight: 600;
      font-size: 12px;
      line-height: 22px;
      color: #484848;
    }
  }
}
.table-container-bottom {
  padding: 0px 60px 0px 60px;
  margin-bottom: 50px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  text-align: left;

  .table-bottom {
    display: flex;
    border-radius: 10px 10px 0px 0px;
    -webkit-box-shadow: 0px 0px 6px 5px rgba(0, 0, 0, 0.25);
    box-shadow: 0px 0px 6px 5px rgba(0, 0, 0, 0.25);
  }
  .bottom-top {
    background-color: #1083a9;
    border-radius: 10px 10px 0px 0px;
    color: #fff;
    font-size: 20px;
    padding-left: 40px !important;
    h2 {
      padding: 15px;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #fff;
      writing-mode: vertical-rl;
      margin: auto;
      transform: rotate(180deg);
    }

    .bottom-wrapper {
      text-align: left;
      padding: 50px 0px 85px 0px;
    }
  }
  .bottom-right {
    flex: 11;
    border-radius: 20px 0px 0px 20px;
    .plan-row-top {
      border-bottom: none;
    }
    span {
      display: block;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
    }
    .wrapper {
      position: relative;
      padding-left: 40px;
    }
    .row-divider {
      height: 15px;
    }
    td {
      text-align: left;
      vertical-align: text-top;
      // padding: 10px 5px;
    }
  }
}
// ------------------paymment

.payment-card {
  position: absolute;
  z-index: 5;
  top: 8px;
  left: 30%;
  width: 40%;
  height: fit-content;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 3rem;
  button {
    border: none;
    background: #ffffff;
    font-weight: 800;
    font-size: 15px;
    line-height: 15px;
    color: #373636;
    margin-top: 10px;
  }
}
.payment-header {
  h3 {
    text-align: left;
    margin-bottom: 2rem;
    font-weight: 800;
    font-size: 18px;
    line-height: 22px;
    color: #484848;
  }
  .group-payment {
    span,
    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
    }
    .payment-options {
      margin-top: 1.5rem;
      margin-bottom: 0.5rem;
      button {
        padding: 15px 0;
      }
    }
  }
  .payment-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100&;
    margin-bottom: 3rem;
    button {
      width: 40%;
    }
  }

  button {
    font-weight: 800;
    font-size: 16px;
    line-height: 19px;
    color: #17282f;
    cursor: pointer;
    padding: 20px 0;
  }
  .span-onclick {
    color: #fff;
    background: #1083a9;
    border-radius: 10px;
  }
}
.payment-form-container {
  display: grid;
  grid-template-columns: 70% 30%;
  justify-content: space-between;
}
.payment-form {
  font-family: "Inter";
  font-style: normal;
  label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  input {
    display: block;
    border: none;
    border-radius: 5px;
    background: #eeeeee;
  }
  input:focus {
    outline: #36ded9;
    border: 1px solid #36ded9;
  }

  .form-payment-label {
    font-weight: 900;
    font-size: 10px;
    line-height: 15px;
    color: #373636;
  }
  .form-payment-description {
    margin-top: 7px;
    font-weight: 800;
    font-size: 8px;
    line-height: 12px;
    color: #a6a6a6;
  }
  .form-payment-label-voucher {
    font-weight: 800;
    font-size: 13px;
    line-height: 19px;
    color: #373636;
  }
  .form-payment-description-voucher {
    text-align: left;
    margin-top: 7px;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #a6a6a6;
  }
  .voucher-btn {
    display: flex;
    justify-content: flex-start;
    .payment-btn-voucher {
      font-weight: 800;
      font-size: 15px;
      line-height: 22px;
      background: #1083a9;
      border-radius: 10px;
      width: 100%;
      height: 50px;
      color: #ffffff;
    }
  }

  .input-card {
    margin-left: 75px;
    width: 400px;
    height: 40px;
  }

  .payment-info-wrapper {
    padding: 15px 0px 0px;
    display: grid;
    grid-template-columns: 40% 60%;
    align-items: center;
    grid-column-gap: 45px;
    text-align: left;
    input {
      height: 40px;
      width: 220px;
    }
    .input-box {
      margin-right: 75px;
      display: grid;
      grid-template-columns: auto auto;
      input {
        width: 100px;
        height: 40px;
      }
    }
  }
  .payment-btn {
    background: linear-gradient(89.76deg, #f95c04 5.71%, #df5000 91.06%);
    border-radius: 10px;
    margin: 20px 0px 0px 20px;
    width: 400px;
    height: 45px;
    color: #ffffff;
  }
}

.payment-form-right {
  margin: 0px 40px 0px -10px;
  padding: 0px 20px;
  text-align: left;
  background: linear-gradient(0deg, #f9fbfc, #f9fbfc),
    linear-gradient(0deg, #f9fbfc, #f9fbfc),
    linear-gradient(0deg, #f9fbfc, #f9fbfc), #f9fbfc;
  border-radius: 20px;
  h3 {
    margin-top: 40px;
    font-weight: 800;
    font-size: 14px;
    line-height: 17px;
    color: #a6a6a6;
  }
  p {
    display: flex;
    align-items: center;
    font-weight: 800;
    font-size: 14px;
    line-height: 17px;
    color: #373636;
    .dot {
      display: grid;
      align-items: center;
      grid-template-columns: 10% 10% 10% 10% 10%;
    }
    .non-dot {
      padding-left: 10px;
    }
  }
}
.payment-right-wrapper {
  display: grid;
  grid-template-columns: 60% 40%;
  text-align: left;
  .info-left {
    span {
      margin: 7px 0px;
      font-weight: 800;
      font-size: 10px;
      line-height: 15px;
      color: #a6a6a6;
    }
  }
  .info-right {
    text-align: right;
    span {
      margin: 7px 0px;
      font-weight: 800;
      font-size: 10px;
      line-height: 15px;
      color: #373636;
    }
  }
  span {
    display: flex;
    flex-direction: column;
  }
}
.input-voucher {
  margin: 10px 0px 13px 0;
  width: 100%;
  height: 50px;
}
.payment-right-footer {
  p {
    margin-top: 20px;
    font-weight: 800;
    font-size: 10px;
    line-height: 15px;
    color: #a6a6a6;
  }
  span {
    font-weight: 900;
    font-size: 18px;
    line-height: 15px;
    color: #373636;
  }
}

.mobile-layout {
  display: none;
}

// Slide animation
.slideRight {
  animation: slide-right 0.5s ease-in;
}
.slideLeft {
  animation: slide-left 0.5s ease-in;
}
.slideRight1 {
  animation: slide-right1 0.5s ease-in;
}
.slideLeft1 {
  animation: slide-left1 0.5s ease-in;
}
@keyframes slide-right {
  from {
    margin-left: -500px;
  }
  to {
    margin-left: 20px;
  }
}
@keyframes slide-right1 {
  from {
    margin-left: -500px;
  }
  to {
    margin-left: 20px;
  }
}
@keyframes slide-left {
  from {
    margin-right: -500px;
  }
  to {
    margin-right: 20px;
  }
}
@keyframes slide-left1 {
  from {
    margin-right: -500px;
  }
  to {
    margin-right: 20px;
  }
}
@media screen and (max-width: 750px) {
  .plan-container {
    .skip-payment {
      justify-content: center !important;
      margin-right: 0px !important;
    }
    .table-divider {
      padding-bottom: 30px;
    }
    h1 {
      font-size: 28px;
    }
    .plan-selection {
      width: 100px;
      display: block;
      margin-bottom: 30px;
    }
    .plan-price {
      font-weight: 900;
      font-size: 20px;
    }
    .plan-duration {
      font-weight: 900;
      margin-bottom: 20px;
    }
    .plan-name {
      font-weight: 900;
      font-size: 14px;
      line-height: 22px;
      color: #484848;
    }
  }
  .payment-header {
    button {
      font-size: 14px !important;
      padding: 10px 5px !important;
    }
  }
  .payment-btn-voucher {
    font-size: 14px !important;
  }
  .payment-card {
    width: 90% !important;
    left: 5% !important;
    padding: 1rem !important;
  }
  .payment-form label {
    margin-left: 5%;
  }
  .plan-table-container {
    display: none !important;
  }
  .mobile-layout {
    position: relative;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    .navigate-back,
    .navigate-forward {
      position: fixed;
      top: 70%;
      border: 2px solid #1083a9;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
    }
    .navigate-back {
      left: 2%;
    }
    .navigate-forward {
      right: 2%;
    }
  }
  .mobile-plan {
    margin: 0px 20px 20px;
    border-radius: 18px;
    padding-top: 2rem;
    min-height: 60vh;
    ul {
      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .benefits-mobile {
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          color: #484848;
          padding: 5px 20% 5px 5%;
          text-align: left;
        }
        .tick {
          margin-right: 5%;
          text-align: right;
          font-size: 14px;
        }
      }
    }
  }
}
