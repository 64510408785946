@mixin text($size, $weight, $color, $lh: 19px) {
  font-weight: $weight;
  font-size: $size;
  line-height: $lh;
  letter-spacing: 0.01em;
  color: $color;
}
.backNav {
  margin: 2.2rem 0 1rem 1rem;
  @include text(19px, 600, #484848, 20px);
  display: none;
  align-items: center;
  //   cursor: pointer;
  text-decoration: none;
  h4 {
    color: #1eafab;
    @include text(18px, 700, #1eafab, 20px);
    margin: 0 1px 0 10px;
  }
}
@media screen and (max-width: 601px) {
  .backNav {
    display: flex;
  }
}
