@mixin text($size, $weight, $color, $lh: 19px) {
  font-weight: $weight;
  font-size: $size;
  line-height: $lh;
  letter-spacing: 0.01em;
  color: $color;
}
@mixin card($raduis: 10px, $color: #fff) {
  filter: drop-shadow(5px 5px 50px rgba(72, 72, 72, 0.15));
  background: $color;
  border-radius: $raduis;
}
@mixin btn {
  @include text(12px, 700, #fff);
  border: none;
  background: linear-gradient(89.76deg, #f95c04 5.71%, #df5000 91.06%);
  padding: 8px 25px;
  border-radius: 7px;
  color: #fff;
}
.filter {
  @include card(8px);
  border: 1px solid #e3e3e3;
  padding: 0.2rem 0.2rem;
  width: 10%;
  height: 2.3rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-right: 2%;
  span {
    @include text(11px, 600, rgba(72, 72, 72, 0.6));
  }
}
.admin-header {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  justify-content: space-between;
  margin: 15px 3rem 2rem;
  padding: 1rem 0px 2rem 2%;
  background: #1eafab;
  border: 1px solid #efefef;
  box-shadow: 0px 20px 50px rgba(220, 224, 249, 0.5);
  border-radius: 10px;
  h5 {
    color: #efefef;
    font-size: 11px;
    margin-bottom: 1rem;
  }
  .admin-header-item {
    display: flex;
    color: #efefef;
    align-items: center;
    img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
    h3 {
      color: #fff;
      margin: 0px;
    }
  }
}
.admin-dashboard {
  display: flex;
  flex-direction: column;
  .admin-dashboard-body {
    display: flex;
    // margin-bottom: 20px;
    padding: 0 50px;
    .sub-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include card();
      padding: 3px 20px;
    }
    span {
      @include text(12px, 600, rgba(72, 72, 72, 0.6));
      padding-left: 10px;
      display: block;
    }
  }
  .card-container {
    display: grid;
    grid-template-columns: auto auto auto auto;
    justify-content: space-between;
    padding: 0 3rem;
    .admin-dashboard-card {
      margin: 1.5rem 0;
      @include card(10px);
      width: 13rem;
      padding: 18px;
      border-radius: 5px;
      border: 2px solid rgba(72, 72, 72, 0.05);
      h3 {
        @include text(2.5vh, 800, #484848);
      }
      p {
        @include text(1.5vh, 600, rgba(72, 72, 72, 0.79));
      }
    }
  }
}
.dashboard-top {
  display: flex;
  align-items: center;
  padding: 2rem 4rem 2rem 3rem;
}

.admin-setting {
  .profileCard {
    margin-top: 0px;
  }
  .table-container {
    margin: 0;
    height: 67vh;
  }
  .personal-info {
    margin: 1.5rem 3rem 1rem;
    label {
      @include text(10px, 600, rgba(72, 72, 72, 0.4));
      margin-bottom: 8px;
    }
    input {
      @include text(12px, 600, #484848);
      height: 2.8rem;
      margin-bottom: 1rem;
    }
    .input-update {
      span {
        padding: 0.8rem 0.5rem 0.8rem 0;
      }
    }
  }
  .setting-container {
    height: 53vh;
  }
  .setting-nav {
    margin: 1rem 3rem 0rem;
    padding: 0.3rem 0.3rem;
    span {
      @include text(12px, 500, #1083a9);
      margin-left: 1.5rem;
    }
    .focus {
      padding: 6px 17px;
    }
  }
  button {
    font-size: 12px;
    padding: 1px 1px;
    height: 2rem;
  }
}
.Userdetails {
  display: grid;
  grid-template-columns: 25% 75%;
  padding: 0 3rem;
  .profileTop {
    margin-left: 0;
  }
}
.profileCard {
  margin-top: -20px;
  margin-left: -3rem;
}
.profileTop {
  margin: 2.2rem 0 2.5rem 3rem;
  @include text(19px, 600, #484848, 20px);
  display: flex;
  align-items: center;
  cursor: pointer;
  h4 {
    margin-bottom: 2px;
  }
}
.Details_navbar {
  margin: 2rem 3rem 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .Details_nav {
    display: flex;
    list-style: none;
    align-items: center;
    li {
      margin: 0 5% 0 0;
      @include text(14px, 500, rgba(72, 72, 72, 0.7));
      display: inline-block;
      cursor: pointer;
      white-space: nowrap;
    }
    .focus {
      color: white !important;
      background: #2d7183;
      box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      padding: 5px 10px;
    }
  }
}
// Userdetails-card
.userdetails-card {
  padding: 1rem 1.5rem;
  @include card;
  height: 67vh;
  .userdetails-Col {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    .userdetail {
      display: flex;
      flex-direction: column;
      margin-right: 10%;
    }
    .disabled {
      border: none;
      background-color: inherit;
    }
  }

  .userdetails-heading {
    @include text(11px, 500, rgba(72, 72, 72, 0.7));
    display: inline-block;
    cursor: pointer;
    margin: 10px 0;
  }
  .userdetails-text {
    @include text(11px, 600, #484848);
    padding: 0.3rem 0.1rem;
    border: 1px solid #d9d9d9;
    background-color: white;
  }
  .header-text {
    @include text(13px, 600, #484848);
    margin-bottom: 0rem;
  }
  .btn-update {
    @include btn;
    margin: 0.5rem 0;
  }
}
.horizontal-line {
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  margin: 1rem 0;
}
// UserBenefitCard
.UserBenefitCard {
  padding: 1rem 1.5rem;
  height: 67vh;
  @include card;
  .benefits-nav {
    ul {
      list-style: none;
      display: flex;
      li {
        @include text(14px, 700, #949494, 19px);
        margin-right: 5%;
      }
    }
  }
  .benefit-header,
  .benefit-list {
    ul {
      list-style: none;
      display: flex;
      justify-content: space-between;
    }
    li {
      @include text(14px, 400, #484848, 19px);
    }
    .header-text {
      @include text(14px, 700, #1083a9, 19px);
    }
  }
}
// UserEcounterCard
.userEncounterCard {
  padding: 1rem 1.5rem;
  height: 67vh;
  @include card;
  .dashboard-top {
    padding: 0px;
  }
  .table-container {
    margin: 0;
    filter: none;
    background: none;
    padding: 0;
  }
}
// Provider
.providerdetails {
  margin: 0 8rem 0 3rem;
  .table-container {
    margin: 0;
  }
  .header-nav {
    display: flex;
    align-items: center;
    .profileTop {
      margin: 0 3rem 0.5rem 0;
      h4 {
        margin-bottom: 4px;
      }
    }
  }
  .provider-content {
    display: grid;
    grid-template-columns: 25% 75%;
    align-items: center;
    column-gap: 40px;
  }
}
// Add Btn
.add-btn {
  padding: 0.7rem;
  margin-left: 1rem;
  @include text(14px, 600, white, 18px);
  background: #1eafab;
  border-radius: 10px;
  border: none;
  cursor: pointer;
}
// userSubscription
.userSubscription {
  padding: 2rem 1.5rem;
  height: 67vh;
  @include card;
  .header-Summary {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-column-gap: 10px;
    justify-content: space-between;
    margin: 0px 0 3rem;
    padding: 12px 20px 20px;
    background: #1eafab;
    border: 1px solid #efefef;
    box-shadow: 0px 20px 50px rgba(220, 224, 249, 0.5);
    border-radius: 10px;
    h5 {
      @include text(10px, 600, rgba(255, 255, 255, 0.79), 20px);
      margin-bottom: 10px;
    }
    .dashboard-summary-item {
      display: flex;
      color: #efefef;
      img {
        width: 35px;
        height: 35px;
        margin-right: 10px;
      }
      h5 {
        @include text(10px, 600, white, 20px);
      }
      h3 {
        @include text(16px, 600, #fff, 20px);
        margin: 0px;
      }
    }
    .dashboard-summary-btn {
      display: none;
    }
  }
}
// providerCard
.provider-card {
  display: flex;
  flex-direction: column;
  height: 67vh;
  @include card(10px, #aad5d4);
  padding: 1.5rem;
  .provider-card-list {
    margin-bottom: 1.5rem;
    h3 {
      @include text(14px, 700, #1083a9, 16px);
    }
    h6 {
      @include text(12px, 400, #484848, 14px);
    }
  }
}
// Claims
.claims {
  display: grid;
  grid-template-columns: 25% 75%;
  column-gap: 40px;
  padding: 0 3rem;
  .profileTop,
  .profileCard {
    margin-left: 0;
  }
  .provider-card-list {
    margin-bottom: 0.5rem;
  }
  .UserBenefitCard {
    margin-right: 3rem;
  }
}
// SalesResps
.sales-content {
  display: grid;
  grid-template-columns: 25% 75%;
  padding: 0 3rem;
  .sales-profile {
    margin-top: -20px;
    margin-left: -3rem;
  }
  .profileTop {
    margin-left: 0;
  }
}
// DeleteAndDeactivateBtn
.btn-container {
  margin-bottom: 1rem;
  .btn-del,
  .btn-deactivate {
    border: none;
    background-color: white;
    @include text(14px, 500, rgba(72, 72, 72, 0.7), 16px);
    cursor: pointer;
  }
  .btn-del {
    margin-left: 1rem;
    color: #f95c04;
  }
}
// voucherCard
.voucherCard {
  @include card;
  height: 67vh;
  padding: 2rem;
  .voucher-list {
    list-style: none;
    display: flex;
    align-items: center;
    li {
      cursor: pointer;
    }
    .list {
      @include text(14px, 700, #949494, 19px);
      white-space: nowrap;
    }
    .focus {
      color: #1083a9;
      border-bottom: 2px solid #1083a9;
    }
    li:first-child {
      margin-right: 27%;
    }
    h3 {
      @include text(14px, 700, #1083a9, 19px);
    }
    div:first-child {
      margin-right: 15%;
    }
  }
}
