// ------------------------------------------------Auth
@mixin form-header-text {
  margin-top: 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 20px;
  line-height: 28px;
  color: #4f4f4f;
}
@mixin text($weight, $size, $lh, $color) {
  font-family: "Inter";
  font-style: normal;
  font-weight: $weight;
  font-size: $size;
  line-height: $lh;
  color: $color;
}
@mixin form-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 33px;
  color: #4f4f4f;
  margin-bottom: 30px;
}
@mixin background-container(
  $top: 80px,
  $bottom: 30px,
  $url: url("../../assets/images/signup-container.png")
) {
  background: $url;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  padding: $top 0px $bottom;
  text-align: center !important;
  min-width: 300px;
  max-width: 450px;
  width: 100%;
}
.signUp-container {
  padding: 5px 7rem 0px;
  overflow: hidden;
  position: relative;

  // header styles..
  .header-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0rem 2rem;
    .nav-menu {
      display: none;
    }
    .nav-bar {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      ul {
        flex: 9;
        display: flex;
        justify-content: center;
        list-style: none;
        margin-bottom: 0px;
      }

      li {
        display: block;
        white-space: nowrap;
        margin: 0 9%;
        span:hover {
          color: black;
        }
      }
      span {
        @include text(500, 14px, 22px, #4f4f4f);
        display: inline-block;
      }
      .nav-top {
        display: none;
        .logo {
          width: 150px;
          height: 88px;
        }
      }
    }
    .nav-end {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .nav-bottom {
      display: none;
    }
  }
  .nav-btn {
    background-color: #f95c04;
    border-radius: 8px;
    width: 100%;
    padding: 0.7rem 1.7rem;
    text-align: center;
    color: white !important;
    font-weight: 900;
    font-size: 14px;
  }
  .logo-container {
    .logo {
      width: 130px;
      height: auto;
    }
  }
}

.signUp-wrapper {
  font-family: "Inter";
  font-style: normal;
  border-radius: 20px;
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0 10px;
  overflow: hidden;

  .signUp-left {
    // margin-left: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h1 {
      @include text(900, 40px, 45px, #2d7183);
      margin: 0px;
    }
    p {
      margin: 10px 0 0;
      @include text(600, 16px, 25px, #4f4f4f);
    }
    .signUp-left-provider {
      font-weight: 900;
      font-size: 14px;
      line-height: 33px;
      color: #f95c04;
      margin: 0px;
    }
    .signUp-img {
      margin-left: -30px !important;
      width: 53%;
      margin-top: 50px;
    }
    .signIn-img {
      margin-left: -5px !important;
      width: 53%;
      margin-top: 50px;
    }
    .otp-img {
      margin-left: -2px !important;
      width: 50%;
      margin-top: 20px;
    }
  }
  .signUp-right {
    display: flex;
    justify-content: flex-end;
    margin-left: 15%;
    .signUp-right-avater {
      position: relative;
      z-index: 20;
      width: 14%;
    }
    .btn-custom {
      display: block;
      margin: 0px auto;
      height: 2.5rem;
      width: 100px;
      background: linear-gradient(89.76deg, #f95c04 5.71%, #df5000 91.06%);
      border-radius: 8px;
      font-weight: 900;
      font-size: 14px;
      color: #ffffff;
      margin-bottom: 5px;
      border: none;
    }
    .back-arrow {
      display: flex;
      align-items: center;
      margin: 5px auto 0px;
      font-weight: 600;
      text-decoration: none;
      a {
        color: #484848;
      }
    }
    .icon {
      font-size: 20px;
      margin: 0px 5%;
      color: #4f4f4f;
      opacity: 0.5;
    }
    h2 {
      @include text(900, 15px, 30px, #2d7183);
      padding: 0 10px;
      margin: 10px 0 0;
    }
    p {
      @include text(500, 13.5px, 33px, #4f4f4f);
      margin: 10px 0 5px;
    }
    .form-bottom {
      input {
        width: 80%;
        height: 40px;
        border: none;
        border-radius: 10px;
      }
      input:focus {
        outline: none;
      }
      div {
        width: 85%;
        margin: 0px auto 25px;
        border: 2px solid #d9d9d9;
        border-radius: 10px;
        display: flex;
        align-items: center;
      }
      div:focus-within {
        border: 2px solid #1083a9;
      }
    }
    .cohort-opt {
      display: flex;
      justify-content: center;
      margin: 30px 0;
      padding: 0 30px;
      .cohort-card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        // height: 8.5rem;
        width: 80%;
        border-radius: 7px;
        background: #afebff;
        align-self: flex-end;
        cursor: pointer;
        p {
          @include text(400, 12px, 14px, #2d7183);
          padding: 0 1.5rem 1rem;
        }
        h3 {
          @include text(700, 14px, 14px, #2d7183);
          padding: 1rem 0 0rem;
        }
      }
      .cohort-card1 {
        display: flex;
        position: relative;
        bottom: -1.2rem;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        margin: 0 0 0 1.2rem;
        width: 80%;
        border-radius: 7px;
        background: #f95c0426;
        align-self: flex-start;
        cursor: pointer;
        p {
          @include text(400, 12px, 14px, #2d7183);
          padding: 0 1.5rem 1rem;
        }
        h3 {
          @include text(700, 14px, 14px, #2d7183);
          padding: 1rem 0 0;
        }
        .img-cohort {
          width: 20%;
        }
      }
      .img-cohort {
        margin-top: 2rem;
        width: 10%;
        height: auto;
      }
      .cohort-card:active {
        border: solid 2px #1083a9;
      }
      .cohort-card1:active {
        border: solid 2px #1083a9;
      }
    }

    .signIn-right-header {
      @include text(900, 24px, 40px, #4f4f4f);
      padding: 50px 0px 40px;
    }
    .form-otp {
      margin-bottom: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .otp {
      border: none;
      font-size: 24px;
      color: #090a0a;
      display: inline-block;
      width: 65px;
      height: 60px;
      border-radius: 8px;
      background: #f1f4fa;
      text-align: center;
    }
    .otp:focus {
      outline: #1083a9;
      border: 2px solid #1083a9;
    }
    .verify-bottom-text {
      font-weight: 400;
      font-size: 15px;
      line-height: 31px;
      text-align: center;
      letter-spacing: 0.4px;
      color: #17282f;
    }
  }
  .verify-container {
    @include background-container($top: 50px, $bottom: 50px);
    margin-left: 30px;
    .otp-input-outline {
      input {
        border: none;
      }
    }
    .otp-input-outline {
      input:focus {
        border: solid 2px #1083a9;
      }
    }
  }
  .login-right-container {
    @include background-container($top: 50px, $bottom: 30px);
    margin: 0 0.7rem 0.7rem 1.3rem;
    .login-right-header {
      @include form-header-text;
      font-size: 26px;
      padding: 30px 0px 40px;
    }
    .forgot-password-text {
      display: inline-block;
      font-weight: 500;
      font-size: 13px;
      line-height: 33px;
      color: #1083a9;
      margin: 8px 0px;
    }
  }

  .signUp-right-container {
    @include background-container(50px, 15px);
    margin-left: 30px;
    position: relative;
    .form-top {
      display: grid;
      grid-template-columns: 50% 50%;
      justify-content: center;
      align-items: center;
      grid-gap: 3%;
      width: 100%;
      padding: 0 9%;
      input {
        box-sizing: border-box;
        width: 80%;
        height: 40px;
        border-radius: 10px;
        border: none;
      }
      input:focus {
        outline: none;
      }
      .input-box {
        width: 100%;
        border: 2px solid #d9d9d9;
        border-radius: 10px;
        display: flex;
        align-items: center;
      }
      .input-box:focus-within {
        border: 2px solid #1083a9;
      }
    }
  }
}
form:invalid button {
  opacity: 0.3;
  pointer-events: none;
}
.err-container {
  display: grid;
  grid-template-columns: 50% 50%;
  padding: 0px 2rem 0px 3rem;
}
.err-box {
  border: 2px solid red !important;
}
.form-item {
  width: 100%;
  margin-bottom: 20px;
}
.err-message {
  display: block;
  color: red;
  font-size: 9px;
  margin-bottom: 10px;
  padding: 0 20px;
}

.err-message-bottom {
  text-align: left;
  display: block;
  color: red;
  font-size: 9px;
  margin: -1.2rem 0px 0.8rem 8%;
}

.forgot-password-container {
  @include background-container($top: 60px, $bottom: 25px);
  margin: 0 0 0 1.7rem;
  .header {
    margin-top: 20px;
  }
  p {
    font-size: 12px !important;
    margin-bottom: 10px !important;
  }
  .back-arrow {
    margin: 15px auto 10px !important;
  }
  .header-reset-password {
    @include text(800, 28px, 28px, #2d7183);
    font-size: 30px;
    padding: 20px 0px 10px;
  }
}

@media screen and (max-width: 1200px) {
  .signUp-wrapper {
    grid-template-columns: 45% 55%;
    .signUp-left {
      h1 {
        font-size: 40px;
        line-height: 60px;
      }
    }
  }
}

@media screen and (max-width: 980px) {
  .signUp-container {
    padding: 5px 30px 0;
  }
  .signUp-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    .signUp-left {
      margin-left: 0;
      .signUp-img {
        width: 100%;
      }
      h1 {
        font-size: 35px;
        line-height: 40px;
      }
    }
    .signUp-right {
      margin: auto !important;
      min-width: 300px !important;
    }
  }
  .header-nav {
    align-items: center !important;
    padding: 0 1rem !important;
    position: relative;
    .no-menu {
      display: none !important;
    }
    .nav-bar {
      position: fixed;
      flex-direction: column;
      background: #ffffff;
      align-items: flex-start !important;
      height: 100vh;
      right: 0;
      top: 0;
      width: 100%;
      z-index: 50;
      padding: 0 0 0 10px;
      .nav-btn {
        width: 95%;
        padding: 0.8rem 0 !important;
        margin: 50px 20px 0;
      }
      ul {
        flex: auto !important;
        flex-direction: column;
        justify-content: space-between !important;
      }
      .nav-end {
        flex: 5;
        flex-direction: column;
        width: 100%;
        align-items: stretch !important;
        text-align: center;
        span,
        div {
          margin-bottom: 50px;
        }
      }
      li {
        display: block;
        width: 100%;
        padding-left: 1rem;
        span {
          font-size: 18px !important;
        }
      }
      .nav-top {
        display: flex !important;
        width: 93%;
        align-items: center;
        justify-content: space-between;
        padding: 0 1.2rem;
        margin: 25px 0 50px;
      }
      .nav-bottom {
        display: flex !important;
        align-items: center;
        justify-content: center;
        margin-left: -2rem !important;
        svg {
          margin: 0 0 20px 20px;
          font-size: 20px;
        }
      }
    }
    .nav-menu {
      display: block !important;
      font-size: 30px;
    }
  }
}

@media screen and (max-width: 570px) {
  .header-nav {
    margin-top: 2rem;
    padding: 0% 5% !important;
    .nav-bar {
      padding: 0 !important;
    }
    .nav-btn {
      width: 90% !important;
    }
  }
  .signUp-wrapper {
    background: white;
    min-width: 300px;
    padding: 20px 5% 0px;
    .b-link {
      color: #1083a9;
      cursor: pointer;
    }
    .signUp-left {
      display: none;
      padding: 50px 30px 40px;
    }
    .signUp-right {
      .signUp-right-avater {
        width: 20%;
      }
      min-width: 300px !important;
      h2 {
        line-height: 30px;
        span {
          display: none;
        }
      }
      p {
        padding: 0 3rem;
        line-height: 20px;
      }
      form {
        p {
          display: block;
        }
      }
      .form-top {
        display: flex;
        flex-direction: column;
        margin: 0 auto 0;
        padding: 0;
        width: 85%;
      }
    }
  }
  .signUp-container {
    padding: 0 0 30px;
  }
  .signUp-right-container {
    @include background-container(
      50px,
      15px,
      url("../../assets/images/mobile-signup.png") !important
    );
    margin-left: 0 !important;
  }
  .login-right-container {
    @include background-container(
      50px,
      30px,
      url("../../assets/images/mobile-login.png") !important
    );
    margin: 0 !important;
  }
  .forgot-password-container {
    @include background-container(
      50px,
      30px,
      url("../../assets/images/mobile-login.png") !important
    );
    .header {
      @include text(900, 18px, 40px, #4f4f4f);
    }
    margin-left: 0;
  }
  .verify-container {
    @include background-container(
      50px,
      30px,
      url("../../assets/images/mobile-login.png") !important
    );
    input {
      margin-right: 8px !important;
    }
    .verify-text {
      display: block !important;
      padding: 1.5rem !important;
      .bold-t {
        display: none;
      }
    }
    margin-left: 0 !important;
  }
  .header-reset-password {
    font-size: 18px !important;
  }
  .btn-custom {
    margin-bottom: 15px !important;
    width: 30% !important;
  }
  .cohort-opt {
    flex-direction: column;
    padding: 0 40px !important;
    .cohort-card1,
    .cohort-card {
      margin: 0px !important;
      width: 100% !important;
    }
  }
}
