@mixin text($weight, $size) {
  font-weight: $weight;
  font-size: $size;
  line-height: 22px;
  letter-spacing: 0.01em;
}
.card-header-table {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #1eafab1a;
  height: 3rem;
  h2 {
    color: #484848;
    @include text(700, 14px);
    margin-bottom: 0;
  }
  p {
    color: #484848b2;
    @include text(400, 11px);
    margin-bottom: 0;
  }
}
.card-header-list {
  margin-bottom: 1rem;
}
.card-modal-container {
  position: relative;
  background: #ffffff;
  height: 50vh;
  border: 10px solid #f9f9f9;
  width: 40%;
  .card-nav {
    display: flex;
    justify-content: left;
    align-items: center;
    list-style: none;
  }
  li {
    @include text(400, 14px);
    margin: 1rem 1rem 0;
    cursor: pointer;
  }
  .focus {
    color: #2d7183 !important;
    font-weight: 700;
  }
  .card-list {
    list-style: none;
  }
  .close {
    position: absolute;
    top: 10px;
    right: 20px;
  }
}
@media screen and (max-width: 980px) {
  .card-modal-container {
    position: absolute;
    top: 1rem;
    left: 2.5%;
    width: 95%;
    padding-top: 3rem;
    height: 80vh;
    z-index: 30;
  }
}
