.logoutContainer {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 20%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  background: white;
  z-index: 100;
  height: 50vh;
  text-align: center;
  .logout {
    color: #2d7183;
    text-decoration: underline;
    cursor: pointer;
    padding-left: 5px;
  }
}
