// ...........Group Member
@mixin input {
  border: 1px solid rgba(189, 189, 189, 0.5);
  padding: 0px 20px;
  width: 90%;
  height: 2.7rem;
  border-radius: 8px;
  margin-bottom: 3.5rem;
}
@mixin text($size, $weight, $color, $lh: 19px) {
  font-weight: $weight;
  font-size: $size;
  line-height: $lh;
  letter-spacing: 0.01em;
  color: $color;
}
@mixin card {
  background: #ffffff;
  border: 1px solid #efefef;
  box-shadow: 0px 20px 30px rgba(220, 224, 249, 0.5);
  border-radius: 8px;
  padding: 30px;
  margin-top: 20px;
}
@mixin btn {
  @include text(12px, 700, #fff);
  border: none;
  background: linear-gradient(89.76deg, #f95c04 5.71%, #df5000 91.06%);
  padding: 8px 25px;
  border-radius: 7px;
  color: #fff;
  margin-bottom: 2rem;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: none;
  border-radius: 10px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  width: 10px;
  height: 10px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: gray;
  width: 10px;
  height: 10px;
}
.group-member {
  @include card;
  margin: 2rem 3rem 0rem;
  height: 64vh;
  .member-card-top {
    display: flex;
    justify-content: space-between;
    span {
      @include text(20px, 700, #484848);
    }
    button {
      @include btn;
      margin-bottom: 0;
    }
  }

  .sub-head {
    @include text(12.5px, 400, #484848, 19px);
  }
  .table-container {
    overflow: auto;
    background: none;
    margin: 0;
    padding: 0;
    height: 50vh;
    tfoot {
      .pagination {
        bottom: -15px !important;
      }
    }
    .member-pay {
      color: red !important;
    }
  }
}
.add-member {
  z-index: 100;
  background-color: white;
  width: 60%;
  position: absolute;
  top: 25%;
  left: 30%;
  h3 {
    @include text(20px, 700, #484848);
    margin: 5% 5% 0;
  }

  .member-form {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    padding: 5%;
    column-gap: 5%;
    input {
      @include input;
      width: 100%;
    }
    .member-bottom {
      margin-top: -30px;
      button {
        @include btn;
        @include text(12px, 700, #fff);
        margin-right: 20px;
      }
      span {
        cursor: pointer;
      }
    }
  }
}
// Userdetails
.detailsContainer {
  margin: 40px;
  position: absolute;
  background: #ffffff;
  width: 70%;
  height: 90vh;
  z-index: 100;
  .setting {
    padding-top: 50px;
  }
  .personal-info,
  .security-container,
  .info {
    height: 60vh;
  }
  .close {
    position: absolute;
    font-size: 30px;
    right: 0;
  }
}

@media screen and (max-width: 980px) {
  .group-member {
    margin: 2rem 3rem 0rem;
    display: none !important;
  }
  .member-mobile {
    .mobile {
      display: block;
    }
    margin: 20px 5% 0;
  }
  .member-top {
    display: grid;
    button {
      border: none;
      height: 5vh;
      min-width: 50px;
      background: #1eafab;
      border-radius: 10px;
      color: white;
      margin-bottom: 1rem;
    }
  }
}
@media screen and (max-width: 550px) {
  .group-member {
    padding: 10px;
    button {
      padding: 0.3rem !important;
    }
    th,
    td {
      padding: 1rem !important;
    }
  }
  .add-member {
    width: 90%;
    top: 5%;
    left: 5%;
    .member-form {
      grid-template-columns: auto !important;
    }
  }
  .detailsContainer {
    margin: 10px;
    width: 95%;
    height: 90vh;
    overflow: auto;
  }
}
