@mixin card {
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 10px;
  padding: 30px;
  margin-top: 20px;
}
@mixin text($size, $weight, $color, $lh: 19px) {
  font-weight: $weight;
  font-size: $size;
  line-height: $lh;
  letter-spacing: 0.01em;
  color: $color;
}
// .overlay {
//   height: 100vh;
//   position: absolute;
//   top: 0;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   background: black;
//   opacity: 0.5;
// }
.notification {
  @include card;
  position: absolute;
  top: 15%;
  left: 25%;
  width: 50%;
  z-index: 200;
  text-align: center;
  h3 {
    @include text(22px, 800, #f95c04);
  }
  p {
    @include text(16px, 400, #000000, 30px);
  }
  .close {
    text-align: right;
    button {
      @include text(14px, 400, #000000, 35px);
    }
  }
  button {
    background: none;
    border: none;
    @include text(14px, 700, #1083a9, 35px);
  }
}

@media screen and (max-width: 600px) {
  .overlay {
    height: auto;
  }
  .notification {
    width: 90%;
    left: 5%;
    height: auto;
    p {
      line-height: 25px !important;
    }
  }
}
