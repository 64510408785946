.mobileHomeContainer {
  margin: 20px 4.5%;
  display: grid;
  grid-template-columns: auto auto auto;
  place-items: center;
  grid-gap: 40px;
  background: #f8f8f8;
  padding: 40px 20px;
  text-align: center;
  justify-content: center;
  align-items: center;
  .icon {
    font-size: 40px;
    opacity: 0.7;
  }
  .sub-heading {
    margin-top: 10px;
    font-size: 13px;
    color: #1eafab;
    white-space: nowrap;
  }
  .mobileHomeItem {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    cursor: pointer;
    a {
      text-decoration: none;
    }
  }
  .mobileHomeItem :active {
    border: 1px solid #1eafab;
  }
}
