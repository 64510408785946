@mixin card {
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 10px;
  padding: 30px;
  margin-top: 20px;
}
@mixin text($size, $weight, $color, $lh: 19px) {
  font-weight: $weight;
  font-size: $size;
  line-height: $lh;
  letter-spacing: 0.01em;
  color: $color;
}
@mixin input {
  border: 1px solid rgba(189, 189, 189, 0.5);
  padding: 0px 10px;
  width: 90%;
  height: 2.7rem;
  border-radius: 8px;
  margin-bottom: 1.5rem;
}
.createGroupContainer {
  z-index: 100;
  background-color: white;
  width: 60%;
  position: absolute;
  top: 8%;
  left: 20%;
  padding-top: 2rem;
  border-radius: 2rem;
  border: solid 5px #2d7183;
  h3 {
    text-align: center;
    @include text(18px, 700, #2d7183);
  }
  p {
    text-align: center;
    @include text(16px, 400, #4f4f4f);
  }
  .createGroupForm {
    padding: 1% 5%;
    display: grid;
    grid-template-columns: auto auto;
    column-gap: 5%;
    input,
    select {
      @include input;
      width: 100%;
    }
    label {
      text-align: left;
      display: block;
      margin-bottom: 0.5rem;
      @include text(14px, 400, #4f4f4f);
    }
  }
  .createGroupBottom {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .btn-cancel {
      display: block;
      background: none;
      @include text(12px, 700, black);
      border: none;
      margin: 0.5rem 0;
    }
    button {
      background: #2d7183;
      width: 15%;
      height: 2.5rem;
      border-radius: 7px;
      @include text(12px, 700, #fff);
    }
    span {
      cursor: pointer;
    }
  }
}
@media screen and (max-width: 600px) {
  .overlay {
    height: 100%;
  }
  .createGroupForm {
    display: block !important;
    input,
    select {
      margin-bottom: 1rem !important;
    }
  }
  .createGroupContainer {
    padding: 1rem;
    border-radius: 0;
    border: none;
    width: 90%;
    left: 5%;
    height: auto;
    p {
      line-height: 25px !important;
    }
  }
  .createGroupBottom {
    button {
      width: 50% !important;
    }
  }
}
