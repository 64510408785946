@mixin text($weight, $size) {
  font-weight: $weight;
  font-size: $size;
  line-height: 22px;
  letter-spacing: 0.01em;
}
.prescription-options {
  display: none;
  .search-mobile {
    margin-bottom: 1rem;
    .search {
      width: 100%;
    }
  }
}
.prescription-container {
  margin: 3rem 3rem;
  background: #ffffff;
  box-shadow: 0px 20px 50px #dce0f980;
  border-radius: 8px;
  min-height: 60vh;
  .mobile-header {
    @include text(700, 18px);
    margin-left: 10px;
    display: none;
  }
  .header {
    display: flex;
    justify-content: flex-start;
    background: rgba(30, 175, 171, 0.14);
    border-radius: 8px;
    padding: 1.5rem 0rem 0rem 0rem;
    h3 {
      @include text(700, 15px);
      color: rgba(72, 72, 72, 0.5);
      margin: 0rem 2.8rem;
      padding-bottom: 0.5rem;
      cursor: pointer;
    }
    .highlight {
      color: #484848;
      border-bottom: 2px solid #2d7183;
    }
  }
  .history-table,
  .prescription-table,
  .appointment-table {
    .table-container {
      margin: 0;
      overflow: hidden;
      height: 55vh;
      background: none;
    }

    th {
      padding: 1rem;
    }
    td {
      padding: 1rem;
    }
  }
  table {
    table-layout: auto;
    margin-left: 2rem;
    th {
      @include text(700px, 13px);
      color: #2d7183;
      text-align: left;
      padding: 1rem 2.5rem;
      white-space: nowrap;
      border-bottom: 1px solid rgba(72, 72, 72, 0.5);
    }
    td {
      text-align: left;
      padding: 1rem 2.5rem;
      white-space: nowrap;
      @include text(600px, 13px);
    }
  }
}

.card-header-list {
  margin-bottom: 1rem;
}
.history-table {
  padding-right: 10px;
  .history-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media screen and (max-width: 980px) {
  .prescription-container {
    box-shadow: none;
    margin: 0rem 4% 0;
    .header {
      padding: 1.5rem 0rem 0rem 0.5rem;
      h3 {
        margin: 0rem 0.5rem;
      }
    }
    .history-table,
    .prescription-table,
    .appointment-table {
      display: none;
    }
    .mobile-header {
      display: block;
    }
    .header {
      background: transparent;
      border: none;
      border-radius: 0;
    }
    min-height: auto;
    table {
      margin: 0;
    }
  }
  .prescription-options {
    display: block;
    margin: 2rem 5%;
  }
  .profile-img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: #484848;
    margin-right: 5%;
  }
}
