.card-carousel {
  .cards {
    display: flex;
    transition: transform 0.5s;
  }

  .card {
    flex: 0 0 100%;
    padding: 20px;
    background: inherit;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    opacity: 0.5;
    transform: scale(0.8);
    transition: opacity 0.5s, transform 0.5s;

    opacity: 1;
    transform: scale(1);

    h2 {
      margin-bottom: 10px;
    }
  }

  .controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    button {
      margin: 0 10px;
      border: none;
      background-color: inherit;
      cursor: pointer;
    }
  }

  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;

    .pagination-dot {
      width: 20px;
      height: 3px;
      background-color: #ccc;
      margin: 0 5px;
      cursor: pointer;

      &.active {
        background-color: white;
      }
    }
  }
}
/* fadeIn animation */
.fadeIn {
  animation: fadein 0.5s ease-in;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
