@mixin text($size, $weight, $color, $lh: 19px) {
  font-weight: $weight;
  font-size: $size;
  line-height: $lh;
  letter-spacing: 0.01em;
  color: $color;
}
@mixin card {
  background: #ffffff;
  border: 1px solid #efefef;
  box-shadow: 0px 20px 30px rgba(220, 224, 249, 0.5);
  border-radius: 8px;
  padding: 30px;
  margin-top: 20px;
}
@mixin btn {
  @include text(12px, 700, #fff);
  border: none;
  background: linear-gradient(89.76deg, #f95c04 5.71%, #df5000 91.06%);
  padding: 8px 25px;
  border-radius: 7px;
  color: #fff;
  margin-bottom: 2rem;
}
.dashboard-summary {
  position: relative;
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-column-gap: 10px;
  justify-content: space-between;
  margin: 0px 3rem;
  padding: 12px 20px 20px;
  background: #1eafab;
  border: 1px solid #efefef;
  box-shadow: 0px 20px 50px rgba(220, 224, 249, 0.5);
  border-radius: 10px;
  h5 {
    @include text(10px, 600, rgba(255, 255, 255, 0.79), 20px);
    margin-bottom: 10px;
  }
  .dashboard-summary-item {
    display: flex;
    color: #efefef;
    img {
      width: 35px;
      height: 35px;
      margin-right: 10px;
    }
    h5 {
      @include text(10px, 600, white, 20px);
    }
    h3 {
      @include text(16px, 600, #fff, 20px);
      margin: 0px;
    }
  }
  .dashboard-summary-btn {
    display: none;
  }
  .group-summary-btn {
    position: absolute;
    right: 10px;
    top: 40%;
    .floating-btn {
      background: white;
      display: flex;
      border-radius: 50%;
      border: none;
      padding: 3px;
      align-items: center;
      justify-content: center;
      svg {
        color: #1eafab;
      }
    }
  }
}
.dashboard-body {
  padding: 0 3rem;
}
// .....Ecard
.ecard {
  @include card;
  height: 27vh;
  padding: 25px 30px;
  min-width: 20rem;
  position: relative;
  .ecard-container {
    display: grid;
    grid-template-columns: 30% 70%;
    align-items: center;
  }
  .print {
    position: absolute;
    right: 25%;
  }
  .ecard-body {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    .ecard-body-middle {
      margin-bottom: 15px;
    }
  }
  .p-img {
    width: 8rem;
    height: 8rem;
    max-width: 200px;
  }
  .ecard-end {
    margin: 1.8rem 0px 0px 15%;
  }
  .flex-content {
    flex: 12;
    display: flex;
    justify-content: space-between;
  }
  h2 {
    @include text(18px, 700, #2d7183);
    img {
      width: 10%;
      margin: 0 0 5px 5px;
    }
  }
  span {
    @include text(12px, 700, #2d7183);
  }
  h5 {
    @include text(10px, 600, #484848);
    font-weight: 600;
    line-height: 16px;
    color: #484848;
    margin-bottom: 0;
  }
}
.activities-card,
.history-card {
  @include card;
  padding: 2rem 2rem 0rem;
  @include text(12px, 400, #2d7183);
  margin-left: 20px;
  .activities-left {
    width: 100%;
    height: 100%;
    color: #484848;
  }
  h3 {
    @include text(14px, 700, #2d7183);
    margin-bottom: 16px;
  }
  .ant-row {
    justify-content: space-between;
    .ant-col {
      div {
        margin-bottom: 15px;
      }
    }
  }
}
.activities-card {
  padding: 1rem 2rem;
  height: 27vh;
}
.history-card {
  padding: 1rem 2rem;
  height: 250px;
}
.card-line {
  display: grid;
  grid-template-columns: 10% 50% 40%;
  grid-column-gap: 5px;
  align-items: center;
  margin-bottom: 10px;
  div {
    justify-self: end;
  }
}
.card-box {
  display: inline-block;
  width: 15px;
  height: 15px;
  background: #f2f1ff;
  margin: 0 15% 0 0 !important;
  vertical-align: middle;
}

.benefits {
  @include card;
  padding: 15px 30px;
  height: 250px;
  .ant-row {
    justify-content: space-between;
  }
  .ant-col {
    div {
      margin-bottom: 10px;
    }
  }
  h3 {
    @include text(14px, 700, #2d7183);
    margin-bottom: 15px;
    cursor: pointer;
  }
  h4 {
    @include text(12px, 600, #484848);
    font-weight: 600;
    line-height: 16px;
    cursor: pointer;
  }
  .benefits-list {
    height: 130px;
    overflow-y: auto;
    span {
      word-wrap: break-word;
    }
  }
  .benefits-container {
    span {
      @include text(13px, 400, #484848);
      display: block;
      margin-bottom: 12px;
    }
  }

  .table-right {
    text-align: right;
  }
  .benefits-header {
    display: flex;
    margin: 5px 0px 20px;
    border-bottom: 1px solid gray;
    .benefits-header-right {
      margin-left: 20px;
    }
  }
}

// ...........Progress card
.progress-card {
  @include card;
  background: #f9f9f9;
  @include text(12px, 500, #000);
  padding: 1.1rem 30px 1rem;
  margin-left: 20px;
  .ant-progress-line {
    width: 80%;
  }
  h3 {
    @include text(14px, 700, #2d7183);
    margin-bottom: 7px;
    cursor: pointer;
  }
  .progress-bar-text {
    @include text(10px, 800, #2d7183);
    margin: 0px 10px 0px 2px;
  }
  .card-top {
    margin-bottom: 23px;
  }
  .ant-row {
    .ant-col {
      span {
        @include text(12px, 500, rgba(72, 72, 72, 0.8));
        color: #f95c04;
        cursor: pointer;
      }
    }
    justify-content: space-between;
    align-items: center;
  }
  .card-bottom {
    text-align: center;
  }
}

// ...........Subscription card
.subscription-card {
  @include card;
  background: #f9f9f9;
  padding: 1.1rem 30px 1rem;
  height: 87%;
  .subscription-card-top {
    justify-content: space-between;
    margin-bottom: 10px;
    .ant-col {
      @include text(12px, 500, rgba(72, 72, 72, 0.8));
      // color: rgba(72, 72, 72, 0.8);
    }
    span {
      color: #f95c04;
      cursor: pointer;
    }
  }
  h3 {
    @include text(14px, 700, #2d7183);
    margin-bottom: 7px;
    cursor: pointer;
  }
  .subscription-card-middle {
    align-items: baseline;
    margin-bottom: 10px;
    @include text(15px, 800, #2d7183);
    .card-subtext {
      font-size: 12px;
      color: rgba(72, 72, 72, 0.8);
      margin-left: 8px;
    }
  }
  span {
    display: block;
    @include text(12px, 500, rgba(72, 72, 72, 0.6));
  }
}
.subscription-dashboard {
  .ant-row {
    margin-top: 10px;
    height: auto;
  }
}

@mixin input {
  border: 1px solid rgba(189, 189, 189, 0.5);
  padding: 0px 20px;
  width: 90%;
  height: 2.7rem;
  border-radius: 8px;
  margin-bottom: 3.5rem;
}

// ...........Support
.support-container {
  @include card;
  margin: 0.5rem 3rem 2rem;
  padding: 2rem 3rem;
  height: 100%;
  h1 {
    @include text(18px, 700, #484848);
    margin-bottom: 2rem;
  }
  .form-container {
    display: grid;
    grid-template-columns: 50% 50%;
    .input-container {
      display: flex;
      flex-direction: column;
    }
    label {
      @include text(12px, 700, rgba(72, 72, 72, 0.4));
      margin-bottom: 8px;
    }
    input {
      @include text(13px, 400, rgba(79, 79, 79, 0.5));
      border: 1px solid rgba(189, 189, 189, 0.5);
      padding: 20px;
      width: 90%;
      height: 2.7rem;

      border-radius: 8px;
      margin-bottom: 1.8rem;
    }
    input:focus {
      outline: 1px solid #1dafac;
    }
    textarea {
      width: 95%;
      height: 86%;
      background: #ffffff;
      border: 1px solid rgba(189, 189, 189, 0.5);
      box-shadow: 0px 10px 30px rgba(220, 224, 249, 0.5);
      border-radius: 8px;
      border: none;
      outline: none;
      padding: 20px;
    }
  }

  button {
    @include btn;
    margin-top: 3%;
  }
}

// ...........Setting
.setting {
  display: grid;
  grid-template-columns: 30% 70%;
}
.preview {
  padding: 20px;
  background: #ffffff;
  position: absolute;
  width: 25%;
  height: auto;
  top: 10%;
  left: 40%;
  right: 40%;
  z-index: 200;
  .btn-preview {
    @include text(16px, 500, #000000);
    display: flex;
    justify-content: space-between;
    padding: 10px;
    button {
      background: none;
      border: none;
    }
  }
  .preImg {
    width: 100% !important;
    border-radius: 0px !important;
    height: 50% !important;
  }
}
.info-container {
  margin: 0rem 2rem 2rem 3rem;
  width: 75%;
  .info {
    height: 70vh;
    @include card;
    margin-top: 25px;
    background: #1eafab4d;
    overflow-y: auto;
  }
  .card-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    h2 {
      @include text(16px, 700, #2d7183);
      margin: 0 10px 0 0;
    }
  }
  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-left: 1px;
    .img-container {
      position: relative;
      margin-bottom: 15px;
      margin-right: 15px;
      .cam {
        position: absolute;
        bottom: 0;
        left: 70px;
        cursor: pointer;
        svg {
          font-size: 10px;
        }
      }
    }
    img {
      width: 100px;
      height: 100px;
      border-radius: 50%;
    }
    .card-section {
      display: flex;
      flex-direction: column;
      .card-top {
        h5 {
          @include text(14px, 900, #484848);
          margin-bottom: 0px;
        }
        margin-bottom: 1rem;
        span {
          @include text(10px, 700, #484848);
        }
      }
      .card-content {
        margin-bottom: 1rem;
        span {
          @include text(12px, 700, #484848);
        }
      }
      h5 {
        @include text(14px, 700, #1083a9);
        margin-bottom: 0px;
      }
      span {
        display: block;
      }
    }
  }
}
.security-container {
  @include card;
  padding: 0px;
  height: 65.5vh;
  margin: 20px 3rem 0 0;
  display: grid;
  grid-template-columns: 50% 50%;
  h2 {
    @include text(16px, 700, #2d7183);
    padding: 0.8rem;
  }
  .security-left {
    padding: 1rem 10%;
  }
  .security-left-items {
    h5 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
      padding: 0.8rem;
      img {
        cursor: pointer;
      }
      span {
        @include text(14px, 500, #484848);
      }
    }
    .focus {
      background: #1eafab;
      border-right: 6px solid #2d7183;
      span {
        color: white;
      }
    }
  }
  .security-right {
    padding: 1rem 10%;
    h2 {
      padding: 0.8rem 0;
    }
    .back {
      display: flex;
      align-items: center;
      span {
        cursor: pointer;
      }
      svg {
        margin: 0 20px 5px 0px;
        font-size: 20px;
      }
    }
    .form-body {
      position: relative;
      width: 100%;
      margin-bottom: 2rem;
      input {
        @include text(12px, 500, #484848);
        width: 100%;
        height: 2.5rem;
        border-radius: 8px;
        padding-left: 20px;
        background: #f9f9f9;
        border: none;
        ::placeholder {
          color: rgba(72, 72, 72, 0.5);
        }
      }
      input:focus {
        outline: 1px solid #1dafac;
      }
      p {
        @include text(12px, 400, rgba(72, 72, 72, 0.7));
        margin-top: 10px;
      }
      .resend-otp {
        text-align: right;
        @include text(12px, 500, #f95c04);
      }
      span {
        @include text(12px, 500, #f95c04);
        position: absolute;
        right: 1rem;
        top: 0.8rem;
        svg {
          color: rgba(72, 72, 72, 0.7);
        }
      }
    }
    button {
      @include btn;
    }
  }
}
.setting-nav {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  .setting-tab-mobile {
    display: none;
  }
  h3 {
    @include text(14px, 500, rgba(72, 72, 72, 0.7));
    display: inline-block;
    cursor: pointer;
    // margin: 0;
    margin: auto 10px;
  }
  .focus {
    h3 {
      color: white;
    }
    background: #2d7183;
    box-shadow: 4px 4px 16px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 5px 10px;
  }
}
.personal-info {
  height: 70vh;
  @include card;
  margin-right: 3rem;
  padding: 2% 0 0 2%;
  label {
    @include text(12px, 600, rgba(72, 72, 72, 0.7));
    margin-bottom: 8px;
    display: block;
  }
  input,
  select {
    @include input;
    margin: 0.3rem 0 1rem;
    @include text(12px, 600, #484848);
    height: 2.5rem;
    box-shadow: 0px 5px 15px rgba(220, 224, 249, 0.5);
    background: white;
  }
  input::placeholder {
    @include text(12px, 600, rgba(72, 72, 72, 0.4));
  }
  input:focus {
    outline: 1px solid #1dafac;
  }
  input:disabled {
    background: #f9f9f9;
    border: 0.5px solid #adadad;
  }
  .full-width {
    input {
      width: 95%;
    }
    h3 {
      @include text(11px, 500, #484848);
      margin-bottom: 2px;
    }
    p {
      @include text(10px, 400, #484848);
    }
  }
  h3 {
    @include text(12px, 600, #484848);
    margin-bottom: 20px;
  }
  .emmergency {
    justify-content: flex-start !important;
    margin-right: 10px;
  }
  .form-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #484848;
    .bottom {
      width: 25%;
    }
    .bottom-right {
      width: 37.5%;
    }
    .bottom-end {
      width: 50%;
    }
    .input-update {
      display: flex;
      width: 90%;
      box-shadow: 0px 5px 15px rgba(220, 224, 249, 0.5);
      height: 3.2rem;
      border-radius: 8px;
      border: 1px solid rgba(189, 189, 189, 0.5);
      input {
        width: 85%;
        border: none;
        outline: none;
        box-shadow: none;
        height: 3rem;
        margin-bottom: 0;
      }
      input:focus {
        outline: none;
      }

      span {
        display: block;
        padding: 0.8rem 0;
        cursor: pointer;
      }
    }
    .input-update:focus-within {
      outline: 1px solid #1dafac;
      span {
        color: #1dafac;
      }
    }
  }
  .select-provider-text {
    margin-right: 2rem;
    text-align: right;
    @include text(10px, 500, #f95c04);
    margin-top: -1rem;
  }
  button {
    @include text(12px, 700, #fff);
    border: none;
    background: linear-gradient(89.76deg, #f95c04 5.71%, #df5000 91.06%);
    padding: 8px 25px;
    border-radius: 7px;
    color: #fff;
    margin-bottom: 2rem;
  }
}
.mobile {
  display: none;
}
.mobile-version {
  display: none;
}
.floating-btn {
  display: none;
  svg {
    color: #1eafab;
    background: white;
    border-radius: 50%;
  }
}
.btn-mobile {
  display: none;
}

@keyframes animate {
  from {
    max-height: 0;
    overflow: hidden;
    opacity: 0.5;
  }
  to {
    max-height: 500px;
    opacity: 1;
  }
}
.dropdown-animate {
  animation-name: animate;
  animation-duration: 0.8s;
  animation-timing-function: ease;
}
.dropdown-animate:hover {
  height: auto;
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 50px 20px 0;
  background: white;
  z-index: 20;
  overflow-y: auto;
  .modal-title {
    margin-bottom: 20px;
  }
}
.no-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 25%;
    height: auto;
    min-height: 7rem;
  }
  .no-data-text {
    @include text(16px, 700, rgba(72, 72, 72, 0.5));
    text-align: center;
    color: #f95c04a1;
    font-size: 14px;
  }
}

@media screen and (max-width: 1024px) {
  .dashboard-summary {
    grid-template-columns: auto auto auto;
    .dashboard-summary-item {
      margin-bottom: 15px;
    }
  }
  .ecard {
    grid-template-columns: 40% 60%;
    .ecard-end {
      display: none;
    }
  }
}
@media screen and (max-width: 980px) {
  .progress-card {
    margin-left: 0;
  }
  .dashboard-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    padding: 0 3rem 0 !important;
    .ant-col-13,
    .ant-col-12,
    .ant-col-11 {
      display: block;
      flex: none;
      width: 100%;
      max-width: none;
    }
    .ant-col-offset-1 {
      margin-left: 0;
    }
  }
  .mobile-version {
    display: block !important;
  }
  .activities-card,
  .history-card {
    min-width: 230px;
    padding: 10px;
    height: 18vh;
    margin-left: 0 !important;
  }
  .ecard {
    height: 18vh;
    .ecard-end {
      display: block;
    }
  }
  .benefits {
    height: auto;
  }
  .dashboard-left {
    min-width: 70px !important;
    align-items: center;
    .non-mobile {
      display: none;
    }
    .mobile {
      display: flex;
    }
    img {
      width: 50% !important;
      height: auto !important;
      margin: 20px 0 50px !important;
    }
    .dashboard-items {
      justify-content: center;
      min-width: 50px !important;
      margin: 0 0 1rem 0 !important;
      span {
        display: none !important;
      }
    }
    .icon {
      margin-left: 0 !important;
      font-size: 30px !important;
    }
    .btn-logout {
      width: 50px !important;
      min-width: 50px !important;
      margin-left: 0 !important;
      span {
        display: none !important;
      }
    }
  }
  .support-container {
    margin: 1.5rem;
    padding: 1rem;
    .form-container {
      input {
        margin-bottom: 0.5rem;
      }
    }
  }
  // .group-member {
  //   margin: 2rem 3rem 0rem;
  // }
  .setting-wrapper {
    .non-mobile {
      display: none;
    }
    .mobile {
      display: flex;
      align-items: center;
    }
    .info-container {
      margin: 0;
      width: 100%;
      padding: 0 50px;
      .info {
        height: auto;
        .card-body {
          display: grid;
          grid-template-columns: auto auto;
          .cam {
            top: 70px;
            left: 70px;
            svg {
              font-size: 3vw;
            }
          }
        }
        .card-section {
          display: grid;
          grid-template-columns: auto auto;
          grid-column-gap: 1.5rem;
        }
      }
      .cam {
        svg {
          font-size: 5vw !important;
        }
      }
    }
  }
  .setting-container {
    width: 100%;
  }
  .personal-info {
    margin: 0rem 0 2rem;
    padding-bottom: 2rem;
    border: none;
    box-shadow: none;
    .emmergency {
      margin-right: 0px !important;
    }
    .form-body {
      flex-direction: column;
      align-items: flex-start;
      div {
        width: 100%;
      }
    }
    .bottom,
    .bottom-right,
    .bottom-end {
      width: 100% !important;
    }
    input {
      margin-bottom: 0.5rem;
      width: 100%;
    }
    select {
      width: 100% !important;
      margin-bottom: 0.5rem;
    }
    .select-provider-text {
      margin: 0 !important;
      text-align: left;
    }
    .full-width {
      input {
        width: 100%;
      }
    }
    button {
      margin: 1.5rem 0 0 !important;
    }
  }
  .setting-nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 20px 5%;
    width: 90%;
    .focus {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: white;
      margin: 0 0 1rem !important;
      width: 100%;
    }
    .setting-tab {
      display: none;
    }
  }
  .setting-tab-mobile {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center;
    margin: 0 0 1rem 0;
    padding: 1rem 0.5rem;
    width: 100%;
    h3 {
      margin: 0;
    }
  }
  .card-header-right {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 20px;
    button {
      @include btn;
      margin-bottom: 0;
      margin-right: 20px;
    }
  }
  .security-container {
    box-shadow: none;
    border: none;
    margin-right: 0;
    .security-right {
      padding: 1rem 0;
    }
  }
  .personal {
    .mobile {
      justify-content: flex-start !important;
      h2 {
        @include text(16px, 700, #484848);
        margin-left: 5%;
      }
    }
    .form-body {
      h4 {
        margin-top: 0;
      }
    }
    h3 {
      margin: 1rem 0;
    }
  }
}
@media screen and (max-width: 790px) {
  .dashboard-focus {
    margin: 0 auto;
    min-width: 3rem;
    width: 3rem;
  }
  .dashboard-summary {
    grid-template-columns: auto auto;
    margin: 0 5% 0 4.5%;
    .dashboard-summary-item {
      align-items: flex-start !important;
      margin-bottom: 15px;
      img {
        margin-top: 5px;
        width: 25px;
        height: 25px;
      }
      h3 {
        font-size: 14px;
      }
      h5 {
        font-size: 10px;
      }
    }
  }
  .dashboard-body {
    padding: 0 5% 0 4.5% !important;
  }
  .activities-card,
  .history-card {
    @include text(12.5px, 400, #2d7183);
    margin-left: 0;
    padding: 20px;
    height: auto;
    h3 {
      @include text(15px, 700, #484848);
    }
  }
  .dashboard-right {
    padding-bottom: 30px;
    overflow-y: auto !important;
  }
  .ecard {
    padding: 10px;
    min-width: 200px;
    height: auto;
    .img-container {
      height: 100%;
    }
    .ecard-container {
      grid-column-gap: 10px;
    }
    .ecard-body {
      justify-content: center;
      padding: 0 10px;
      margin-left: 0px !important;
    }
    .p-img {
      width: 85%;
      height: 100%;
      min-width: 100px;
    }
    .ecard-body-middle {
      margin-bottom: 0;
      h5 {
        margin-bottom: 0;
      }
    }
    h2 {
      @include text(16px, 700, #2d7183);
      img {
        margin-left: 10px;
      }
    }
    span {
      @include text(12.5px, 700, #2d7183);
      word-break: break-all;
    }
    h5 {
      @include text(11px, 600, #484848);
    }
  }
  .benefits {
    height: auto;
    h3 {
      @include text(13px, 700, #1083a9);
    }
    span {
      @include text(12.5px, 400, #484848);
    }
  }
  .progress-card {
    margin-left: 0;
    .card-bottom {
      text-align: left;
    }
  }

  .support-container {
    margin: 1rem;
    padding: 1rem;
    .form-container {
      grid-template-columns: auto;
      margin-bottom: 5rem;
      input {
        margin-bottom: 0.5rem;
        width: 95%;
      }
    }
  }

  .setting-container {
    height: auto !important;
    width: 100%;
  }

  .security-container {
    grid-template-columns: auto;
    .vertical-line {
      display: none;
    }
  }
  .info-container {
    padding: 0 20px !important;
  }

  .card {
    background: #ffffff;
    border: 1px solid #efefef;
    box-shadow: 0px 20px 50px rgba(220, 224, 249, 0.5);
    border-radius: 10px;
    margin: 1.5rem 0 1rem;
    padding: 1rem;
    width: 100%;
    .details {
      cursor: pointer;
    }
    .search-mobile {
      margin-bottom: 1rem;
      .search {
        width: 100%;
      }
    }
    .sub-tag {
      color: rgba(72, 72, 72, 0.7);
      font-size: 12px;
    }
    .mobile {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .card-top {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #484848;
      h4 {
        margin: 0 0 5px 7px;
        @include text(16px, 700, #484848);
      }
    }

    input {
      background: #ffffff;
      border: 1px solid #e3e3e3;
      box-shadow: 5px 5px 50px rgba(72, 72, 72, 0.05);
      border-radius: 10px;
      margin-bottom: 0.8rem;
      height: 2.5rem;
      padding-left: 30px;
      width: 100%;
    }
    ul {
      list-style: none;
      li {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0.5rem;
      }
    }
    .card-wrapper {
      background: #f9f9f9;
      padding: 1rem;
      margin: 0 -1rem 1rem -1rem;
      .sub-tag {
        color: rgba(72, 72, 72, 0.7);
        font-size: 12px;
        margin-bottom: 15px;
      }
      .sponsor {
        @include text(14px, 600, #f95c04);
      }
      .left,
      p {
        @include text(12px, 700, rgba(72, 72, 72, 0.7));
      }
      .right {
        @include text(12px, 700, #484848);
      }
      .header {
        @include text(16px, 700, rgba(72, 72, 72, 0.7));
      }
      h3 {
        @include text(14px, 600, rgba(72, 72, 72, 0.7));
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .info {
    .cam {
      left: 130px !important;
    }
    .preview {
      width: 90%;
      left: 5%;
      right: 5%;
      .preImg {
        width: 100% !important;
      }
    }
    .card-body {
      display: flex !important;
      flex-direction: column;
      img {
        width: 120px !important;
        height: 120px !important;
      }
      .cam {
        left: 95px !important;
        bottom: 10px !important;
      }
    }
    .card-section {
      display: flex !important;
      flex-direction: column;
    }
  }
}
@media screen and (max-width: 500px) {
  .dashboard-left {
    .icon {
      font-size: 25px !important;
    }
  }

  .dashboard-summary {
    display: flex;
    flex-direction: column;
  }
  .dashboard-items {
    margin: 0 auto 1rem !important;
  }
  .mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .mobile-header {
      @include text(16px, 700, #484848);
      margin-bottom: 1rem;
    }
  }
  .btn-mobile {
    display: block;
    width: 100%;
    background: #f9f9f9;
    margin-top: 10px;
    border: none;
    border-radius: 10px;
    height: 5vh;
  }
  li {
    list-style: none !important;
  }
  .ecard-container {
    display: none !important;
    grid-template-columns: auto !important;
    .p-img {
      margin-top: 20px;
      height: 90%;
    }
    .ecard-body {
      justify-content: flex-start !important;
      display: block;
      margin: 20px 0 0 0;
      h2 {
        display: none;
      }
    }
  }
  .ecard-end {
    display: none !important;
    margin: 1rem 0 0 !important;
  }
  .progress-card {
    margin-left: 0;
  }
  .activities-card,
  .history-card,
  .benefits {
    padding: 10px;
  }
  .card-line {
    div {
      justify-self: flex-start;
    }
  }
  .benefits-container {
    display: none !important;
  }
  .activities-header {
    display: none !important;
  }
  .activities-left {
    display: none !important;
  }
  .show {
    display: grid !important;
    margin-top: 10px;
  }
  .dashboard-summary-btn {
    display: block !important;
    background: #ffffff;
    border: 2.22034px solid #f7f7f7;
    border-radius: 10px;
    width: 100%;
    height: 2.5rem;
  }
  .floating-btn {
    display: block;
    text-align: right;
  }
  // .group-member {
  //   padding: 10px;
  //   button {
  //     padding: 0.3rem !important;
  //   }
  //   th,
  //   td {
  //     padding: 1rem !important;
  //   }
  // }
  // .add-member {
  //   width: 90%;
  //   top: 0;
  //   left: 5%;
  //   .member-form {
  //     grid-template-columns: auto !important;
  //   }
  // }
  .security-right {
    padding: 0 !important;
  }
  .mobile-version {
    display: block !important;
  }
  .back-btn {
    padding: 0px;
  }
  .subscription-card,
  .progress-card {
    padding: 1rem !important;
  }
  .security-container {
    .mobile {
      justify-content: flex-start;
    }
  }
  .non-mobile {
    display: none !important;
  }
  .info-container {
    padding: 15px !important;
    .card-header {
      h2 {
        font-size: 16px;
      }
    }
    .card-header-right {
      justify-content: flex-start !important;
    }
  }
}
