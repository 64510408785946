.printCard {
  overflow: hidden;
  // overflow-y: scroll;
  position: absolute;
  z-index: 200;
  padding: 10px;
  height: 415px;
  background: #ffffff;
  width: 690px;
}
.profileUserContainer {
  margin-bottom: 10px;
  width: 672px;
  height: 395px;
  display: flex;
  p {
    font-family: Inter;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    span:nth-child(2) {
      color: #2d7183;
    }
  }
  .profileLogo {
    margin-top: -30px;
    margin-bottom: 50px;
    margin-left: 10px;
    width: 70%;
  }
  .profileUserDetials {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 50px;
    width: 336px;
    background: #e0f4fc;
    .detailsSpace {
      display: flex;
      justify-content: space-between;
    }
    .block {
      position: absolute;
      bottom: 60px;
      left: 0;
      height: 200px;
      width: 40px;
      background: #1dafad;
    }
  }
  .nameTag {
    font-family: Inter;
    font-size: 24px;
    font-weight: 500;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0em;
    padding-top: 20px;
    color: #ffffff;
  }
  .profileUserPic {
    width: 336px;
    background: #2d7183;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    .picFrame {
      margin-top: 40px;
      background-color: white;
      width: 160px;
      height: 190px;
      overflow: hidden;
    }
    img {
      width: 160px;
      height: 190px;
    }
  }
}
.ecardBackContainer {
  .ecardBack {
    background: #2d7183;
    position: relative;
    width: 672px;
    height: 395px;
    display: flex;
    align-items: center;
    justify-content: center;
    .cardBack {
      position: absolute;
    }
    .web {
      position: absolute;
      bottom: 10px;
      color: #ffffff;
    }
  }
}
.printBtn {
  position: absolute;
  bottom: 10px;
  button {
    background: #1dafad;
    border-radius: 10px;
    padding: 10px;
    border: none;
    color: #ffffff;
  }
  button:nth-child(2) {
    margin-left: 50px;
  }
}
@media screen and (max-width: 1020px) {
  .printCard {
    height: 415px;
    width: 70%;
  }
  .profileUserContainer {
    width: 100%;
    height: 400px;
    .profileUserDetials {
      width: 100%;
    }
    .profileUserPic {
      width: 100%;
    }
  }
}
// @media screen and (max-width: 600px) {
//   .printCard {
//     height: 300px;
//     width: 90%;
//   }
//   .profileUserContainer {
//     width: 100%;
//     height: 300px;
//     .profileUserDetials {
//       width: 100%;
//     }
//     .profileUserPic {
//       width: 100%;
//     }
//   }
// }
