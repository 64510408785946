* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Inter";
  font-style: normal;
}
// -----------------------------------------------------------------
@mixin text($size, $weight, $color, $lh: 19px) {
  font-weight: $weight;
  font-size: $size;
  line-height: $lh;
  letter-spacing: 0.01em;
  color: $color;
}
@mixin card($raduis: 10px, $color: #fff) {
  filter: drop-shadow(5px 5px 50px rgba(72, 72, 72, 0.15));
  background: $color;
  border-radius: $raduis;
}
/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.dashboard-container {
  display: grid;
  grid-template-columns: 19% 81%;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
}
.dashboard-left {
  @include text(16px, 500, #484848);
  background: rgba(248, 248, 248, 0.8);
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  margin: 0px auto;
  img {
    width: 110px;
    height: 110px;
    margin: -25px 0 0 2.8rem;
  }
  .icon {
    font-size: 25px;
    color: #7b7b7b;
    margin-left: 10px;
  }
  .btn-logout {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #747474;
    border-radius: 10px;
    height: 40px;
    color: white;
    width: 50%;
    min-width: 7rem;
    margin: 20px 0 0 3rem;
    border: none;
    span {
      @include text(12px, 700, #ffffff);
      margin-right: 10px;
      color: #ffffff;
    }
  }
  .dashboard-items {
    display: flex;
    align-items: center;
    margin: 0px 1rem 1rem 2rem;
    background: rgba(248, 248, 248, 0.8);
    @include text(15px, 500, #484848);
    cursor: pointer;
    padding: 0.8rem 5px;
    vertical-align: middle;
    border: none;
    span {
      display: inline-block;
      margin-left: 15px;
      color: inherit;
      text-align: left;
    }
    img {
      width: 25px;
      height: 25px;
      margin: 0px;
      color: #7b7b7b;
    }
  }
  .dashboard-focus {
    background: #1eafab;
    box-shadow: 0px 27.1019px 67.7548px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    color: white !important;
    svg {
      color: white;
    }
  }
  .dashboard-menu {
    display: flex;
    flex-direction: column;
    margin-top: -15px;
  }
}
.dashboard-right {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: hidden;
  padding-bottom: 2rem;
  .dashboard-header {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 4.5%;
    h1 {
      @include text(16px, 700, #484848);
      cursor: pointer;
    }
    img {
      width: 40px;
      height: 40px;
      margin-right: 10px;
      border-radius: 50%;
    }
  }
}
.table-container {
  position: relative;
  @include card;
  margin: 0 3rem;
  padding: 0 1rem;
  height: 60vh;
  table {
    width: 100%;
    thead {
      border-bottom: 1px solid rgba(72, 72, 72, 0.5);
    }
    th {
      @include text(13px, 700, #2d7183);
      color: #2d7183;
      text-align: left;
      padding: 1rem;
      white-space: nowrap;
    }
    tbody {
      tr {
        cursor: pointer;
      }
    }
    td {
      text-align: left;
      padding: 1rem;
      white-space: nowrap;
      @include text(12.5px, 400, #484848);
    }
  }
  .pagination {
    list-style: none;
    // position: absolute;
    // bottom: 0px;
    // left: 20%;
    // right: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    span {
      @include text(12px, 400, rgba(72, 72, 72, 0.7), 18px);
    }
    .page-item {
      margin: 0 0.7rem;
      .page-link {
        display: block;
        text-align: center;
        cursor: pointer;
      }
    }
    .focus {
      @include card;
      border: 1px solid #e3e3e3;
      height: 1.5rem;
      width: 1.5rem;
      border-radius: 5px;
      display: flex;
      .page-link {
        margin: auto;
      }
    }
  }
}
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  // cursor: pointer;
}
.search {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  svg {
    position: absolute;
    left: 10px;
    top: 13px;
    z-index: 10;
  }
  input {
    padding-left: 40px;
    height: 2.3rem;
    width: 100%;
    @include card(8px);
    border: 1px solid #e3e3e3;
    @include text(12px, 600, rgba(72, 72, 72, 0.6));
  }
}

.pageContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  .btn-404 {
    background: #ffe2d2;
    padding: 5px;
    @include text(13px, 500, #f95c04);
    margin-bottom: 10px;
  }
  .heading-404 {
    @include text(40px, 900, #2d7183, 50px);
    margin-bottom: 5px;
  }
  .text-404 {
    @include text(19px, 600, #484848, 25px);
  }
  .img-404 {
    width: 35%;
  }
}
@media screen and (max-width: 600px) {
  .slideRight {
    animation: slide-right 0.5s ease-in;
  }
  @keyframes slide-right {
    from {
      margin-left: -500px;
    }
    to {
      margin-left: 20px;
    }
  }
  .dashboard-container {
    grid-template-columns: auto;
    justify-content: inherit;
    .menu {
      position: absolute;
      left: 10px;
      top: 8px;
      z-index: 20;
    }
  }
  .dashboard-left {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 10%;
    z-index: 200;
    background: #f9f9f9;
  }
  .dashboard-header {
    text-align: center;
    .dasboardNav {
      .dasboardNavItems {
        display: flex;
        align-items: center;
        svg {
          margin-right: 10px;
          font-size: 28px;
          color: #484848cc;
        }
        a {
          text-decoration: none;
          color: #484848cc;
          font-size: 16px;
          font-weight: 600;
        }
      }
      .dasboardNavContainer {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 100;
        background: #fff;
        display: flex;
        flex-direction: column;
        gap: 20px;
        text-align: left;
        padding: 40px 30px 0px;
        .divider {
          margin: -30px -30px 10px;
          height: 5px;
          background: #d9d9d9;
        }
      }

      .dashboardProfile {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 2rem;

        button {
          background: inherit;
          border: none;
        }
        .profile {
          display: flex;

          h3 {
            color: #2d7183;
            font-weight: 700;
            font-size: 16px;
          }
          p {
            color: #2d7183;
            font-weight: 500;
            font-size: 14px;
          }
        }
      }
    }
  }
  .pageContainer {
    .heading-404 {
      @include text(24px, 900, #2d7183, 50px);
      margin-bottom: 5px;
    }
    .text-404 {
      @include text(12px, 600, #484848, 25px);
      text-align: center;
    }
    .img-404 {
      width: 75%;
    }
  }
}
